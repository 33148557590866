// assets
import { IconBrandChrome, IconHelp } from "@tabler/icons";

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: "sample-docs-roadmap",
  type: "group",
  children: [
    {
      id: "update-profile",
      title: "Update Profile",
      type: "item",
      url: "/update-profile",
      icon: icons.IconBrandChrome,
      breadcrumbs: false,
    },
    {
      id: "sample-page",
      title: "Sample Page",
      type: "item",
      url: "/sample-page",
      icon: icons.IconBrandChrome,
      breadcrumbs: false,
    },
    {
      id: "sample-admin-page",
      title: "Sample Admin Page",
      type: "item",
      url: "/sample-admin-page",
      icon: icons.IconBrandChrome,
      role: ["admin"],
      breadcrumbs: false,
    },
    {
      id: "sample-page-role",
      title: "Sample Page Role",
      type: "item",
      url: "/sample-page-role",
      icon: icons.IconBrandChrome,
      role: ["admin"],
      breadcrumbs: false,
    },
    {
      id: "sample-add-customer-page",
      title: "Sample Add Customer Page",
      type: "item",
      url: "/sample-add-customer-page",
      icon: icons.IconBrandChrome,
      // role:['manager'],
      breadcrumbs: false,
    },
    {
      id: "documentation",
      title: "Documentation",
      type: "item",
      url: "https://google.com",
      icon: icons.IconHelp,
      external: true,
      target: true,
    },
  ],
};

export default other;
