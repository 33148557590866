import * as React from "react";
import { useRoutes, useLocation } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";

// ==============================|| ROUTING RENDER ||============================== //
import menuItem from "menu-items";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
export default function ThemeRoutes() {
  const navigate = useNavigate();
  const dataAccount = useSelector((state) => state.account);
  let location = useLocation();
  React.useEffect(() => {
    console.log("useLocation trigger")
    setTimeout(() => {
      checkRoleAccessed();
    }, 100);
  }, [location,dataAccount]);
  const checkRoleAccessed = () => {
    // console.log("menuItem:", menuItem);
    // console.log("Router Change!!!!  ", location);
    //  navigate("/pages/login/login3", { state: { from: "somethingelse" } });
    console.log("check role access!!",dataAccount)
    if(!dataAccount.account)return null;
    try {
      menuItem.items.map((items) => {
        if (items.type == "group") {
          items.children.map((item, index) => {
            if (
              item.url == location.pathname &&
              item?.role &&
              dataAccount?.account?.role
            ) {
              const itemFind = item?.role.find(
                (e) =>
                  e?.toLowerCase() == dataAccount?.account?.role.toLowerCase()
              );
              if (!itemFind) {
                navigate("/page-403");
              }
            }
          });
        }
      });
    } catch (error) {
      console.log("something went wrong!", error);
      navigate("/");
    }
  };
  return useRoutes([MainRoutes, AuthenticationRoutes]);
}
