// material-ui
import { useTheme } from '@mui/material/styles';

import { Card, Avatar } from "@mui/material";

const Logo = () => {
  const theme = useTheme();

  return (
    <Card style={{ display:'flex',justifyContent:'center',alignItems:'center'}}>
    <Avatar
        // variant={"square"}
        alt="The image"
        src={
          "https://www.shutterstock.com/shutterstock/photos/413126230/display_1500/stock-vector-abstract-green-swirl-logo-sample-vector-illustration-413126230.jpg"
        }
        style={{
          width: 50,
          height: 50,
          marginRight:10,
          resize: "inline"
        }}
        
      />
      <h1>Khoa Pug</h1>
      </Card>
  );
};

export default Logo;
