// project imports
import config from "config";

// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  loading: false,
  account: null,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const accountReducer =  (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_LOGIN:
      return {
        ...state,
        account: action.account,
      };
      case actionTypes.AUTH_LOGOUT:
        return {
          ...state,
          account: null,
        };

    default:
      return state;
  }
};

export default accountReducer;
